import React from 'react'
import { Slide } from 'react-slideshow-image'

import styles from './style.module.scss'

const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: true
}

export default ({ slideImages, featuredImage }) => {
    const images = [
        <div className="each-slide">{featuredImage}</div>,
        ...slideImages.map(image => (
            <div className="each-slide" key={image}>
                <div
                    style={{ backgroundImage: `url(${image})` }}
                    className={styles.slideImage}
                ></div>
            </div>
        ))
    ]
    return (
        <div className={styles.slideShow}>
            <Slide {...properties}>{images}</Slide>
        </div>
    )
}
