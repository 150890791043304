import React, { useRef, useMemo, useState } from 'react'

import Popup from 'reactjs-popup'

import Input from '../input'

import { Link } from 'gatsby'

import Button from '../button'

import styles from './style.module.scss'

import { TiDelete } from 'react-icons/ti'

import { getToken } from '../../helpers'

import Select from '../select-search'

import { useNumberOfChecks, useSaveOffer, useCurrentPath } from '../hooks'

import format from '../format'

import Form from '../form/api-form'

import { isEmpty } from 'lodash-es'

const YouNeedToBeSignedIn = ({ close }) => {
    const path = useCurrentPath()
    return (
        <div className={styles.modal}>
            <TiDelete className={styles.close} onClick={close} />
            <h2>Submit an offer</h2>
            <p>You need to be signed in to submit an offer</p>
            <div className={styles.actions}>
                <Link className={styles.signUp} to="/sign-up">
                    I don't have an account.
                </Link>
                <Button
                    className={styles.signIn}
                    link={'/sign-in?redirect=' + path}
                >
                    Sign in
                </Button>
            </div>
        </div>
    )
}

const SubmitOffer = ({ close, property }) => {
    const checks = useNumberOfChecks()

    const formRef = useRef({})

    const { statusCode, loading, response, submit } = useSaveOffer({
        formRef,
        additionalData: {
            property: property.wordpress_id
        }
    })

    const success = useMemo(() => statusCode === 200, [statusCode])

    return (
        <div className={styles.modal}>
            <TiDelete className={styles.close} onClick={close} />
            <h2>Submit an offer</h2>
            <p>Owner is asking:</p>
            <table className={styles.priceDetails}>
                <tbody>
                    <tr>
                        <th>Price</th>
                        <td>
                            {format({
                                value: property.acf.price,
                                type: 'price'
                            })}
                        </td>
                    </tr>
                    <tr>
                        <th>Number of checks</th>
                        <td>
                            {format({
                                value: property.acf.number_of_checks,
                                type: 'emptyValue'
                            })}
                        </td>
                    </tr>
                </tbody>
            </table>
            <h3 className={styles.yourOffer}>Your offer</h3>
            {success ? (
                <div className={styles.success}>
                    Your offer has been sent, we will notify you once the owner
                    responds.
                </div>
            ) : (
                <Form
                    statusCode={statusCode}
                    ref={formRef}
                    loading={loading}
                    response={response}
                    onSubmit={() => submit()}
                >
                    <Input
                        name="price"
                        label="Price"
                        type="number"
                        placeholder="Price"
                    />
                    <Select
                        options={checks}
                        label="Number of checks"
                        name="number_of_checks"
                    />
                    <Button className={styles.sendOffer}>Send offer</Button>
                </Form>
            )}
        </div>
    )
}

export default ({ children, property, disabled }) => {
    const [isSignedIn] = useState(!isEmpty(getToken()))
    return (
        <Popup
            disabled={disabled}
            trigger={children}
            modal
            contentStyle={{ border: 0, width: 'initial' }}
        >
            {close =>
                isSignedIn ? (
                    <SubmitOffer close={close} property={property} />
                ) : (
                    <YouNeedToBeSignedIn close={close} />
                )
            }
        </Popup>
    )
}
