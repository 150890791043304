import React, { useState, useEffect, useMemo } from 'react'

import styles from './style.module.scss'

import { Link } from 'gatsby'

import OptionalImage from '../optional-image'

import { IoIosArrowBack } from 'react-icons/io'

import { FiMapPin } from 'react-icons/fi'

import { AiOutlineSafetyCertificate } from 'react-icons/ai'

import { formatter } from '../format'

import cn from 'classnames'

import Button from '../button'

import Form from '../form'

import Input from '../input'

import PhoneInput from '../phone-input'

import { TiInfoLarge } from 'react-icons/ti'

import pluralize from 'pluralize'

import { Element, scroller } from 'react-scroll'

import Slideshow from './slideshow'

import { get } from 'lodash-es'

import SubmitAnOfferPopup from '../submit-an-offer-popup'

export default ({ property, data }) => {
    const [viewContactSection, setViewContactSection] = useState(false)

    useEffect(() => {
        if (viewContactSection)
            scroller.scrollTo('contact', {
                duration: 1000,
                delay: 100,
                smooth: true,
            })
    }, [viewContactSection])

    const backendUrl =
        process.env.GATSBY_PROTOCOL + '://' + process.env.GATSBY_BASE_URL

    const images = get(property, 'acf.images')
        ? get(property, 'acf.images')
              .replace(/HOME_URL/g, backendUrl)
              .split('\n')
        : null

    const permitNo = get(property, 'acf.permit_number')

    return (
        <div className={styles.singleProperty}>
            <div className={styles.mainSection}>
                <div className="wrapper">
                    <div className="row">
                        <div className="col-xs-12">
                            <Link
                                to="/properties-and-enquiries"
                                className={styles.back}
                            >
                                <IoIosArrowBack />
                                <span> Back to search results</span>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            {images ? (
                                <Slideshow
                                    slideImages={images}
                                    featuredImage={
                                        <OptionalImage
                                            object={property}
                                            path="featured_media.localFile.childImageSharp.fluid"
                                            className={styles.image}
                                        />
                                    }
                                />
                            ) : (
                                <OptionalImage
                                    object={property}
                                    path="featured_media.localFile.childImageSharp.fluid"
                                    className={styles.image}
                                />
                            )}
                        </div>
                        <div className="col-md-1" />
                        <div className="col-xs-12 col-md-5">
                            <div className={styles.detailsColumn}>
                                <h1
                                    className={styles.title}
                                    dangerouslySetInnerHTML={{
                                        __html: property.title,
                                    }}
                                />
                                <div>
                                    <div className={styles.permitNo}>
                                        <AiOutlineSafetyCertificate />
                                        Permit: {permitNo}
                                    </div>
                                    <div className={styles.location}>
                                        <FiMapPin />
                                        {getLocation({ property, data })}
                                    </div>
                                    <PropertyDetails
                                        property={property}
                                        data={data}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {viewContactSection ? (
                <ContactSection
                    property={property}
                    data={data}
                    onClose={() => setViewContactSection(false)}
                />
            ) : (
                <OverviewSection
                    property={property}
                    data={data}
                    onShowContact={() => setViewContactSection(true)}
                />
            )}
        </div>
    )
}

const OverviewSection = ({ property, onShowContact, data }) => {
    const [expandOverview, setExpandOverview] = useState(false)
    const [viewFeesSection, setViewFeesSection] = useState(false)

    const status = useMemo(() => getStatus({ property, data }), [
        property,
        data,
    ])

    useEffect(() => {
        if (viewFeesSection) {
            scroller.scrollTo('fees', {
                duration: 1000,
                delay: 100,
                smooth: true,
            })
        }
    }, [viewFeesSection])

    return (
        <section className={styles.overviewSection}>
            <div className="wrapper">
                <div className="row">
                    <div
                        className={cn(
                            'col-xs-12 col-md-6',
                            styles.overviewColumn
                        )}
                    >
                        <h2>Overview</h2>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: property.content,
                            }}
                            className={cn(styles.overview, {
                                [styles.expand]: expandOverview,
                            })}
                        />
                        <div
                            className={styles.readMore}
                            onClick={() => setExpandOverview(!expandOverview)}
                        >
                            {expandOverview ? 'Read less' : 'Read more'}
                        </div>
                    </div>
                    <div
                        className={cn(
                            'col-xs-12 col-md-6',
                            styles.buttonsColumn
                        )}
                    >
                        <Button onClick={() => setViewFeesSection(true)}>
                            View Fees
                        </Button>
                        <Button onClick={onShowContact}>Request Viewing</Button>
                        <SubmitAnOfferPopup
                            property={property}
                            disabled={status.slug === 'not-available'}
                        >
                            <div>
                                <Button
                                    disabled={status.slug === 'not-available'}
                                >
                                    Submit an Offer
                                </Button>
                            </div>
                        </SubmitAnOfferPopup>
                        {viewFeesSection && (
                            <FeesSection
                                property={property}
                                onClose={() => setViewFeesSection(false)}
                            />
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

const PropertyDetails = ({ property, data }) => {
    const type = useMemo(() => getType({ property, data }), [property, data])

    const status = useMemo(() => getStatus({ property, data }), [
        property,
        data,
    ])

    return (
        <div className={styles.details}>
            <div className="row">
                <div className="col-xs-6">
                    <div className={styles.type}>
                        <div className={styles.typeName}>Type</div>
                        <div
                            className={styles.typeValue}
                            dangerouslySetInnerHTML={{
                                __html: type,
                            }}
                        />
                    </div>
                </div>
                <div className="col-xs-6">
                    <div className={cn(styles.type, styles.right)}>
                        <div className={styles.typeName}>Price</div>
                        <div className={styles.typeValue}>
                            {formatter.price(property.acf.price)}
                        </div>
                    </div>
                </div>
                <div className="col-xs-6">
                    <div className={cn(styles.type)}>
                        <div className={styles.typeName}>Status</div>
                        <div className={styles.typeValue}>{status.name}</div>
                    </div>
                </div>
                <div className="col-xs-6">
                    <div className={cn(styles.type, styles.right)}>
                        <div className={styles.typeName}>Bedrooms</div>
                        <div className={styles.typeValue}>
                            {pluralize(
                                'room',
                                +property.acf.number_of_rooms,
                                true
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FeesSection = ({ property, onClose }) => {
    return (
        <Element name="fees" className={styles.feesSection}>
            <div className={styles.close} onClick={onClose}>
                X
            </div>
            <h2>Fees</h2>
            <div className={styles.feeDetails}>
                <div className="row">
                    <div className="col-xs-6">
                        <div className={styles.type}>
                            <div
                                className={cn(styles.typeName, styles.feeName)}
                            >
                                Brokerage fee by Seller
                            </div>
                            <div className={styles.typeValue}>
                                {formatter.fees(
                                    property.acf.brokerage_fee_by_seller
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className={styles.type}>
                            <div
                                className={cn(styles.typeName, styles.feeName)}
                            >
                                Brokerage fee by Buyer
                            </div>
                            <div className={styles.typeValue}>
                                {formatter.fees(
                                    property.acf.brokerage_fee_by_buyer
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className={styles.type}>
                            <div
                                className={cn(styles.typeName, styles.feeName)}
                            >
                                Property ePortal fees
                            </div>
                            <div className={styles.typeValue}>
                                {formatter.fees(
                                    property.acf.property_eportal_fees
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-6">
                        <div className={cn(styles.type)}>
                            <div
                                className={cn(styles.typeName, styles.feeName)}
                            >
                                Brokerage fee sharing to Listing user
                            </div>
                            <div className={styles.typeValue}>
                                {formatter.fees(
                                    property.acf
                                        .brokerage_fee_sharing_to_listing_user
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className={cn(styles.type)}>
                            <div
                                className={cn(styles.typeName, styles.feeName)}
                            >
                                Brokerage fee sharing by Cobroker(s)
                            </div>
                            <div className={styles.typeValue}>
                                {formatter.fees(
                                    property.acf
                                        .brokerage_fee_sharing_by_cobrokers
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Element>
    )
}
const ContactSection = ({ onClose }) => {
    return (
        <Element name="contact" className={styles.contactSection}>
            <Form
                className={styles.card}
                requiredFields={['name', 'mobile', 'email']}
            >
                <div className={styles.close} onClick={onClose}>
                    X
                </div>
                <h3 className={styles.title}>
                    <TiInfoLarge /> Request Viewing
                </h3>
                <div className={styles.body}>
                    <Input label="Name" placeholder="Your name" required />
                    <PhoneInput
                        label="Mobile"
                        placeholder="Your mobile"
                        required
                    />
                    <Input label="Email" placeholder="Your email" required />
                    <div className={styles.options}>
                        <Input
                            type="checkbox"
                            name="accept_privacy_policy"
                            required
                        >
                            I agree to the propertyeportal
                            <Link
                                to="/privacy-policy"
                                className={styles.privacy}
                            >
                                privacy policy
                            </Link>
                        </Input>
                    </div>

                    <Button className={styles.submit}>Submit</Button>
                </div>
            </Form>
        </Element>
    )
}
function getLocation({ property, data }) {
    const location = data.allWordpressWpLocation.nodes.filter((l) =>
        property.location.find((_l) => _l === l.wordpress_id)
    )
    return location.map((l) => l.name).join(', ')
}

function getType({ property, data }) {
    const types = data.allWordpressWpType.nodes.filter((l) =>
        property.type.find((_l) => _l === l.wordpress_id)
    )
    return types.map((t) => t.name).join(', ')
}

function getStatus({ property, data }) {
    const status = data.allWordpressWpStatus.nodes.find((l) =>
        property.status.find((_l) => _l === l.wordpress_id)
    )
    return status
}
