import React from 'react'

import SEO from '../components/seo'

import { graphql } from 'gatsby'

import Layout from '../components/layout'

import SingleProperty from '../components/single-property'

export default ({ data }) => {
    const property = data.allWordpressWpProperty.nodes[0]

    return (
        <Layout
            breadcrumb={[
                {
                    title: 'Home',
                    link: '/',
                },
                {
                    title: 'Properties',
                    link: '/properties-and-enquiries',
                },
                {
                    title: property.title,
                },
            ]}
            marketTicker
            noSecondaryHeader
        >
            <SEO
                title={property.title}
                description={property.content.substring(0, 155)}
            />
            <SingleProperty property={property} data={data} />
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        allWordpressWpProperty(filter: { slug: { eq: $slug } }) {
            nodes {
                wordpress_id
                title
                content
                location
                broker
                status
                type
                featured_media {
                    alt_text
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 900) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
                acf {
                    price
                    size
                    number_of_rooms
                    number_of_checks
                    year
                    brokerage_fee_by_buyer
                    brokerage_fee_by_seller
                    brokerage_fee_sharing_by_cobrokers
                    brokerage_fee_sharing_to_listing_user
                    property_eportal_fees
                    images
                    permit_number
                }
            }
        }
        allWordpressWpStatus {
            nodes {
                wordpress_id
                name
                slug
            }
        }
        allWordpressWpType {
            nodes {
                name
                wordpress_id
            }
        }
        allWordpressWpLocation {
            nodes {
                name
                wordpress_id
            }
        }
        allWordpressWpBroker {
            nodes {
                name
                wordpress_id
            }
        }
    }
`
