import React, { Fragment, useEffect, useState } from 'react'

import Select from '../select'
import Input from '../input'

import countries from './country-codes.json'

import axios from 'axios'

async function getMyCountry() {
    try {
        // https://us-central1-eportal-210613.cloudfunctions.net/geolocation
        const { data } = await axios.get(
            'https://us-central1-eportal-210613.cloudfunctions.net/geolocation'
        )

        const country = countries.find(c => c.code === data.country)

        return country
    } catch (e) {}

    return {}
}

export default ({ label = 'Phone', placeholder = 'Your phone' }) => {
    const [country, setCountry] = useState({})

    useEffect(() => {
        getMyCountry().then(country => {
            setCountry(country)
        })
    }, [])

    return (
        <Fragment>
            <Select
                name="country"
                required
                label="Country"
                defaultValue={country.code}
            >
                {countries.map(c => (
                    <option key={c.code} value={c.name + ' ' + c.dial_code}>
                        {c.name + ' ' + c.dial_code}
                    </option>
                ))}
            </Select>
            <Input label={label} placeholder={placeholder} required />
        </Fragment>
    )
}
