import React from 'react'
import Img from 'gatsby-image'
import Thumbnail from '../single-project/default-thumbnail'
import get from 'lodash.get'

export default ({ object, path, ...props }) =>
    get(object, path) ? (
        <Img {...props} fluid={get(object, path)} />
    ) : (
        <Thumbnail {...props} />
    )
