import React from 'react'

import Select from 'react-select'

import * as primaryPalette from '../../styles/primary-pallete'

import styles from './style.module.scss'

import { FaStarOfLife } from 'react-icons/fa'

const theme = theme => ({
    ...theme,
    borderRadius: '0.3rem',
    colors: {
        ...theme.colors,
        // primary25: secondaryPalette.base,
        primary: primaryPalette.base
    }
})

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        minHeight: '50px',
        boxShadow: 'inset 4px 4px 0 0 ' + primaryPalette.gray1,
        border: '1px solid ' + primaryPalette.gray8
    })
}

export default ({ label, name, required, ...props }) => {
    return (
        <>
            <label htmlFor={name} className={styles.label}>
                {label}
                {required ? <FaStarOfLife className={styles.required} /> : null}
            </label>
            <Select
                theme={theme}
                styles={customStyles}
                {...props}
                name={name}
                required={required}
            />
        </>
    )
}
